'use client';

import { usePathname } from 'next/navigation';
import { useMemo } from 'react';

import { ITopNavigation } from '@squadnet/frontend-cms-gql';

import { TopNavigation } from '@/app/_ui/components/Cms/TopNavigation';

interface NavProps {
  data: Partial<ITopNavigation>;
}

const isNormalPage = (pathname: string) => {
  const pages = [
    '/about-us',
    '/contact-us',
    '/blog',
    '/pricing',
    '/help-center',
    '/unsubscribe',
    '/privacy-policy',
    '/terms-and-conditions',
  ];
  return pages.findIndex(page => pathname.startsWith(page)) > -1;
};

export const Navigation = ({ data }: NavProps) => {
  const pathname = usePathname() || '';

  const { isRelative, isTransparent, black } = useMemo(() => {
    let isRelative = false;
    let black = false;
    let isTransparent = true;
    if (pathname.startsWith('/blog')) {
      isRelative = true;
    }
    if (isNormalPage(pathname)) {
      black = true;
      isTransparent = false;
    }
    return {
      isRelative,
      black,
      isTransparent,
    };
  }, [pathname]);

  return <TopNavigation black={black} data={data} isRelative={isRelative} isTransparent={isTransparent} animated />;
};
